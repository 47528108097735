import React from "react"
import { Link } from "gatsby"

const Header = () => (
  <header>
    <h1>Daniela<br />Pes</h1>

    <menu>
      <ul>
        <li><Link to="/spira" activeClassName="active" title="Spira">spira</Link></li>
        <li><Link to="/about" activeClassName="active" title="About" partiallyActive>about</Link></li>
        <li><Link to="/tour" activeClassName="active" title="Tour">tour</Link></li>
      </ul>
    </menu>
  </header>
)

export default Header
